import { Module } from 'vuex';
import to from 'await-to-js';
import moment from 'moment';
import { State } from '@/models/State';
import { bloqifyFirestore, bloqifyFunctions, bloqifyStorage, firebase } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { Asset, InterestRateTypes } from '@/models/assets/Asset';
import { AssetInterestRate, InterestRateStatus } from '@/models/assets/InterestRates';
import { AssetEndDate, EndDateStatus } from '@/models/assets/EndDates';
import { AssetInterestRateType, InterestRateTypeStatus } from '@/models/assets/InterestRateTypes';
import { Investor } from '@/models/users/User';
import { AssetAccounts } from '@/models/assets/Deposit';
import { AssetPaymentMethod, MandateStatus } from '@/models/opp/Opp';
import { i18n } from '@/boot/i18n';
import { Vertebra, generateState, mutateState } from '../utils/skeleton';
import { generateFileMd5Hash } from '../utils/files';

const SET_ASSET = 'SET_ASSET';

export interface InvoiceActionParam {
  assetId: string;
  invoiceId: string;
  profile?: AssetAccounts;
}

export interface CreateManualInvoiceParam {
  assetId: string;
  title: string;
  subject: string;
  receiver: string;
  allInvestors: boolean;
  amount: number;
  provision?: number;
  date: number;
  expirationDate: number;
  proForma: boolean,
  vatAmount?: number;
  vatFormula: 'noVat' | 'included' | 'added';
}

export interface SetInvoicePlanningParam {
  assetId: string;
  createInvoices: boolean;
  sendInvoices: boolean;
  overrideInvoiceSchedules: boolean;
  sendInvoiceDate: number;
  createInvoiceDate: number;
}

export interface CreateAutomatedInvoiceParam {
  assetId: string;
  time: number;
  proForma: boolean;
  prevPeriodsTimes?: number[];
}

export const assetChecks = (asset: Asset, oppIntegration: boolean): boolean => {
  const requiredFields = [
    'name', 'city', 'country', 'startDateTime', 'endDateTime', 'expectedRepaymentDate', 'administrationFee',
    'euroMin', 'totalValueEuro', 'sharePrice', 'emissionCost', 'dividendsFormat', 'returnsAfterEnd',
    ...(oppIntegration ? ['projectURL', 'paymentMethod'] : []),
  ];

  // Allow zeroes
  if (requiredFields.some((field): boolean => !asset[field] && asset[field] !== 0)) {
    return false;
  }

  if (asset.dividendsFormat.length === 0) {
    return false;
  }

  if ((asset.loanTaker || []).length === 0) {
    return false;
  }

  return true;
};

export default <Module<Vertebra, State>> {
  state: generateState(),
  mutations: {
    [SET_ASSET](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async getAssetById(
      { commit },
      { id }: { id: string },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'getAssetById' });

      const [getAssetError, getAssetData] = await to(bloqifyFirestore.collection('assets').doc(id).get());
      const error = getAssetError || (!getAssetData?.exists && Error('Not Found'));
      if (error) {
        commit(SET_ASSET, { status: DataContainerStatus.Error, payload: error, operation: 'getAssetById' });
        return;
      }

      commit(SET_ASSET, { status: DataContainerStatus.Success, payload: getAssetData!.data(), operation: 'getAssetById' });
    },
    async createAsset(
      { commit },
      { asset }: { asset: Asset },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'createAsset' });

      const storageRef = bloqifyStorage.ref();
      const assetRef = bloqifyFirestore.collection('assets').doc();
      const assetClone = { ...asset };
      const files: { [key: string]: File[] } = {};
      const storageChildren: { file: File, ref: firebase.storage.Reference }[] = [];
      const filesKeyNames = ['images', 'floorPlanImages', 'prospectus', 'brochure'];

      // Building propper objects: asset (to send to the database) and files (to send to storage)
      // Setting up an array with all the files to be uploaded
      filesKeyNames.forEach((keyName): void => {
        assetClone[keyName] = assetClone[keyName].map((file: File): string => {
          const fullPath = `assets/${assetRef.id}/${encodeURIComponent(decodeURIComponent(file.name))}`;

          storageChildren.push({
            file,
            ref: storageRef.child(fullPath),
          });

          // Creating / pushing files object
          if (files[keyName]) {
            files[keyName].push(file);
          } else {
            files[keyName] = [file];
          }

          // The asset object only needs the filename as a reference for the database
          return fullPath;
        });
      });

      // Uploading all files including hashes
      try {
        await Promise.all(storageChildren.map(async (child): Promise<any> => {
          const md5Hash = await generateFileMd5Hash(child.file, true);

          return child.ref.put(child.file, { customMetadata: { md5Hash } });
        }));
      } catch (e) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: e, operation: 'createAsset' });
      }

      // Data fixing
      if (assetClone.startDateTime) {
        // @ts-ignore
        assetClone.startDateTime = firebase.firestore.Timestamp.fromMillis(assetClone.startDateTime);
      }
      if (assetClone.endDateTime) {
        // @ts-ignore
        assetClone.endDateTime = firebase.firestore.Timestamp.fromMillis(assetClone.endDateTime);
      }
      if (assetClone.expectedRepaymentDate) {
        // @ts-ignore
        assetClone.expectedRepaymentDate = firebase.firestore.Timestamp.fromMillis(assetClone.expectedRepaymentDate);
      }

      // @ts-ignore
      assetClone.createdDateTime = firebase.firestore.FieldValue.serverTimestamp();

      // @ts-ignore
      assetClone.updatedDateTime = firebase.firestore.FieldValue.serverTimestamp();

      const updateTotalValueShares = (): number => {
        if (!isNaN(assetClone.totalValueEuro) && !isNaN(assetClone.sharePrice)) {
          // It can be 0/0 = NaN
          return (assetClone.totalValueEuro / assetClone.sharePrice) || 0;
        }

        return 0;
      };

      assetClone.totalValueShares = updateTotalValueShares();
      assetClone.sharesAvailable = assetClone.totalValueShares;
      assetClone.totalValueEuro = assetClone.totalValueEuro || 0;
      assetClone.currentValueEuro = 0;
      assetClone.euroMin = assetClone.euroMin || 0;
      assetClone.sharePrice = assetClone.sharePrice || 0;
      assetClone.deleted = false;
      assetClone.sendInvoices = true;
      assetClone.createInvoices = true;

      const [lastAssetError, lastAsset] = await to(
        bloqifyFirestore.collection('assets')
          .orderBy('customId', 'desc')
          .limit(1)
          .get(),
      );
      if (lastAssetError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: lastAssetError, operation: 'createAsset' });
      }

      assetClone.customId = Number(lastAsset!.docs[0]?.data().customId || 0) + 1;
      // Opp requires a unique email to create a wallet, we use the support email and the custom id to form a new email
      assetClone.emailAddressDummy = this.state.settings!.support_email.split('@').join(`+${assetClone.customId}@`);

      if (this.state.settings!.investorPortalEnabled) {
        assetClone.projectURL = `${this.state.settings!.base_url}/property/${assetRef.id}`;
      }

      if (assetClone.loanTaker && assetClone.loanTaker.length) {
        const loanTakerInvestorRefs = (assetClone.loanTaker as unknown as string[])
          .filter((id, index, arr): boolean => arr.indexOf(id) === index)
          .map((lt): firebase.firestore.DocumentReference => bloqifyFirestore.collection('investors').doc(lt)) || [];
        assetClone.loanTaker = loanTakerInvestorRefs;

        const [loanTakerInvestorDataError, loanTakerInvestorData] = await to(Promise.all(loanTakerInvestorRefs.map(async (loanTaker): Promise<Investor> => {
          const [getLoanTakerError, getLoanTaker] = await to(loanTaker.get());
          if (getLoanTakerError || !getLoanTaker?.exists) {
            throw Error(getLoanTakerError?.message || 'Loantaker not found');
          }
          return getLoanTaker!.data() as Investor;
        })));
        if (loanTakerInvestorDataError) {
          return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: loanTakerInvestorDataError, operation: 'createAsset' });
        }

        if (this.state.settings!.oppIntegration && (assetClone.paymentMethod !== AssetPaymentMethod.None || assetClone.psp)) {
          // We check that all loanTakers have a walletId
          if (loanTakerInvestorData!.some((investor): boolean => !investor.walletId)) {
            return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: Error('Loantaker´s must have a walletId'), operation: 'createAsset' });
          }
        }
      }

      if (assetClone.notary) {
        // Transform the received object into a db reference
        const notaryInvestorRef = bloqifyFirestore.collection('investors').doc((assetClone.notary as unknown as string));
        assetClone.notary = notaryInvestorRef;

        const [getNotaryError, getNotary] = await to(notaryInvestorRef.get());
        if (getNotaryError || !getNotary?.exists) {
          throw Error(getNotaryError?.message || 'Notary not found');
        }
        const notaryData = getNotary!.data() as Investor;

        if (this.state.settings!.oppIntegration && (assetClone.paymentMethod !== AssetPaymentMethod.None || assetClone.psp)) {
          // We check that the notary has a walletId
          if (!notaryData.walletId) {
            return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: Error('Notary must have a walletId'), operation: 'createAsset' });
          }
        }
      }

      const [createError] = await to(assetRef.set(assetClone));
      if (createError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: createError, operation: 'createAsset' });
      }

      if (assetClone.returnsAfterEnd) {
        const assetInterestRateRef = assetRef.collection('interestRates').doc('initial');
        const assetInterestRate: AssetInterestRate = {
          asset: assetRef,
          deleted: false,
          amount: assetClone.returnsAfterEnd,
          status: InterestRateStatus.Applied,
          appliedDateTime: firebase.firestore.Timestamp.fromDate(new Date(0)), // We set the default applied date to 01/01/1970
          createdDateTime: assetClone.createdDateTime,
          updatedDateTime: assetClone.updatedDateTime!,
        };

        const [createInterestRateError] = await to(assetInterestRateRef.set(assetInterestRate));
        if (createInterestRateError) {
          return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: createInterestRateError, operation: 'createAsset' });
        }
      }

      if (assetClone.endDateTime) {
        const assetEndDateRef = assetRef.collection('endDates').doc('initial');
        const assetEndDate: AssetEndDate = {
          asset: assetRef,
          deleted: false,
          endDate: assetClone.endDateTime,
          numberOfPeriods: assetClone.numberOfPeriods,
          invoiceFrequency: assetClone.invoiceFrequency,
          status: EndDateStatus.Applied,
          appliedDateTime: firebase.firestore.Timestamp.fromDate(new Date(0)), // We set the default applied date to 01/01/1970
          createdDateTime: assetClone.createdDateTime,
          updatedDateTime: assetClone.updatedDateTime!,
        };

        const [createEndDateError] = await to(assetEndDateRef.set(assetEndDate));
        if (createEndDateError) {
          return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: createEndDateError, operation: 'createAsset' });
        }
      }

      if (assetClone.interestRateType) {
        const assetInterestRateTypeRef = assetRef.collection('interestRateTypes').doc('initial');
        const assetInterestRateType: AssetInterestRateType = {
          asset: assetRef,
          deleted: false,
          type: assetClone.interestRateType,
          ...(assetClone.interestRateType === InterestRateTypes.FixedAmortisation && { amount: assetClone.fixedAmortisationRate }),
          status: InterestRateTypeStatus.Applied,
          appliedDateTime: firebase.firestore.Timestamp.fromDate(new Date(0)), // We set the default applied date to 01/01/1970
          createdDateTime: assetClone.createdDateTime,
          updatedDateTime: assetClone.updatedDateTime!,
        };

        const [createEndDateError] = await to(assetInterestRateTypeRef.set(assetInterestRateType));
        if (createEndDateError) {
          return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: createEndDateError, operation: 'createAsset' });
        }
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, payload: { id: assetRef.id }, operation: 'createAsset' });
    },
    async updateAsset(
      { commit },
      { asset }: { asset: Asset },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'updateAsset' });

      const storageRef = bloqifyStorage.ref();
      const { id: assetId, ...assetData } = asset;
      let assetClone: Asset;
      if (asset.activated) {
        const {
          startDateTime,
          endDateTime,
          invoiceFrequency,
          numberOfPeriods,
          dateRangeFormat,
          interestRateType,
          returnsAfterEnd,
          ...rest
        } = assetData;
        assetClone = rest as Asset;
      } else {
        assetClone = assetData;
      }

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const files: { [key: string]: File[] } = {};
      const storageChildren: { file: File, ref: firebase.storage.Reference }[] = [];
      const filesKeyNames = ['images', 'floorPlanImages', 'prospectus', 'brochure'];

      // Building propper objects: asset (to send to the database) and files (to send to storage)
      // Setting up an array with all the files to be uploaded
      filesKeyNames.forEach((keyName): void => {
        assetClone[keyName] = assetClone[keyName].map((file: File): string => {
          const fullPath = `assets/${assetRef.id}/${encodeURIComponent(decodeURIComponent(file.name))}`;

          storageChildren.push({
            file,
            ref: storageRef.child(fullPath),
          });

          // Creating / pushing files object
          if (files[keyName]) {
            files[keyName].push(file);
          } else {
            files[keyName] = [file];
          }

          // The asset object only needs the filename as a reference for the database
          return fullPath;
        });
      });

      // The comparison of the md5Hash could have been done here via JavaScript (customMetadata.md5Hash) but it's also possible via
      // Firestore rules. The only caveat is that the error handling is not good at all, we cannot identify
      // what kind of error we are getting from the rules, only no permission.
      let storageResultsAndErrors: firebase.storage.UploadTaskSnapshot | firebase.functions.HttpsError[];
      try {
        storageResultsAndErrors = await Promise.all(storageChildren.map(async (child): Promise<any> => {
          const md5Hash = await generateFileMd5Hash(child.file, true);

          // Return all errors if there are any
          return child.ref.put(child.file, { customMetadata: { md5Hash } })
            .catch((err): Error => err);
        }));
      } catch (e) {
        // Set error if there is any other kind of error than a FirebaseStorageError
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: e, operation: 'updateAsset' });
      }

      // Check if there is any other FirebaseStorageError error than 'storage/unauthorized',
      // since it's the only one we have to check if the md5 exists (check rules)
      const differentError = storageResultsAndErrors.some(
        // @ts-ignore (types are not correct, it does not support code 'storage/unauthorized')
        (resultOrError): boolean => resultOrError.code && resultOrError.code !== 'storage/unauthorized',
      );
      if (differentError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: Error('Error uploading files.'), operation: 'updateAsset' });
      }

      // Data fixing
      if (assetClone.startDateTime) {
        // @ts-ignore
        assetClone.startDateTime = firebase.firestore.Timestamp.fromMillis(assetClone.startDateTime);
      }
      if (assetClone.endDateTime) {
        // @ts-ignore
        assetClone.endDateTime = firebase.firestore.Timestamp.fromMillis(assetClone.endDateTime);
      }
      if (assetClone.expectedRepaymentDate) {
        // @ts-ignore
        assetClone.expectedRepaymentDate = firebase.firestore.Timestamp.fromMillis(assetClone.expectedRepaymentDate);
      }

      // @ts-ignore
      assetClone.updatedDateTime = firebase.firestore.FieldValue.serverTimestamp();

      if (assetClone.loanTaker && assetClone.loanTaker.length) {
        const loanTakerInvestorRefs = (assetClone.loanTaker as unknown as string[])
          .filter((id, index, arr): boolean => arr.indexOf(id) === index)
          .map((lt): firebase.firestore.DocumentReference => bloqifyFirestore.collection('investors').doc(lt)) || [];
        assetClone.loanTaker = loanTakerInvestorRefs;

        const [loanTakerInvestorDataError, loanTakerInvestorData] = await to(Promise.all(loanTakerInvestorRefs.map(async (loanTaker): Promise<Investor> => {
          const [getLoanTakerError, getLoanTaker] = await to(loanTaker.get());
          if (getLoanTakerError || !getLoanTaker?.exists) {
            throw Error(getLoanTakerError?.message || 'Loantaker not found');
          }
          return getLoanTaker!.data() as Investor;
        })));
        if (loanTakerInvestorDataError) {
          return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: loanTakerInvestorDataError, operation: 'updateAsset' });
        }

        if (this.state.settings!.oppIntegration && (assetClone.paymentMethod !== AssetPaymentMethod.None || assetClone.psp)) {
          // We check that all loanTakers have a walletId
          if (loanTakerInvestorData!.some((investor): boolean => !investor.walletId)) {
            return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: Error('Loantaker´s must have a walletId'), operation: 'updateAsset' });
          }
        }
      }

      if (assetClone.notary) {
        // Transform the received object into a db reference
        const notaryInvestorRef = bloqifyFirestore.collection('investors').doc((assetClone.notary as unknown as string));
        assetClone.notary = notaryInvestorRef;

        const [getNotaryError, getNotary] = await to(notaryInvestorRef.get());
        if (getNotaryError || !getNotary?.exists) {
          throw Error(getNotaryError?.message || 'Notary not found');
        }
        const notaryData = getNotary!.data() as Investor;

        if (this.state.settings!.oppIntegration && (assetClone.paymentMethod !== AssetPaymentMethod.None || assetClone.psp)) {
          // We check that the notary has a walletId
          if (!notaryData.walletId) {
            return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: Error('Notary must have a walletId'), operation: 'updateAsset' });
          }
        }
      }

      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));
        if (getAssetError || !getAssetSuccess?.exists) {
          throw getAssetError || Error('Asset not found.');
        }

        const dbAsset = getAssetSuccess?.data() as Asset;

        const updateAssetObject: { [key: string]: any } = {
          ...assetClone,
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        };

        if (assetClone.returnsAfterEnd && assetClone.returnsAfterEnd !== dbAsset.returnsAfterEnd) {
          const assetInterestRateRef = assetRef.collection('interestRates').doc('initial');
          const assetInterestRate: AssetInterestRate = {
            asset: assetRef,
            deleted: false,
            amount: assetClone.returnsAfterEnd,
            status: InterestRateStatus.Applied,
            // We set the default applied date to 01/01/1970
            appliedDateTime: firebase.firestore.Timestamp.fromDate(new Date(0)),
            createdDateTime: dbAsset.createdDateTime,
            updatedDateTime: assetClone.updatedDateTime!,
          };

          transaction.set(
            assetInterestRateRef,
            assetInterestRate,
          );
        }

        if (assetClone.endDateTime && assetClone.endDateTime !== dbAsset.endDateTime) {
          const assetEndDateRef = assetRef.collection('endDates').doc('initial');
          const assetEndDate: AssetEndDate = {
            asset: assetRef,
            deleted: false,
            endDate: assetClone.endDateTime,
            status: EndDateStatus.Applied,
            numberOfPeriods: assetClone.numberOfPeriods,
            invoiceFrequency: assetClone.invoiceFrequency,
            // We set the default applied date to 01/01/1970
            appliedDateTime: firebase.firestore.Timestamp.fromDate(new Date(0)),
            createdDateTime: dbAsset.createdDateTime,
            updatedDateTime: assetClone.updatedDateTime!,
          };

          transaction.set(
            assetEndDateRef,
            assetEndDate,
          );
        }

        if (assetClone.interestRateType) {
          const assetInterestRateTypeRef = assetRef.collection('interestRateTypes').doc('initial');
          const assetInterestRateType: AssetInterestRateType = {
            asset: assetRef,
            deleted: false,
            type: assetClone.interestRateType,
            ...(assetClone.interestRateType === InterestRateTypes.FixedAmortisation && { amount: assetClone.fixedAmortisationRate }),
            status: InterestRateTypeStatus.Applied,
            appliedDateTime: firebase.firestore.Timestamp.fromDate(new Date(0)), // We set the default applied date to 01/01/1970
            createdDateTime: dbAsset.createdDateTime,
            updatedDateTime: assetClone.updatedDateTime!,
          };

          transaction.set(
            assetInterestRateTypeRef,
            assetInterestRateType,
          );
        }

        // We don't need to update shares fields if these fields remain the same
        if (dbAsset.totalValueEuro !== asset.totalValueEuro || dbAsset.sharePrice !== asset.sharePrice) {
          const updateTotalValueShares = (): number => {
            if (!isNaN(assetClone.totalValueEuro) && !isNaN(assetClone.sharePrice)) {
              // It can be 0/0 = NaN
              return (assetClone.totalValueEuro / assetClone.sharePrice) || 0;
            }

            return 0;
          };

          const totalValueShares = updateTotalValueShares();

          updateAssetObject.totalValueShares = totalValueShares;
          updateAssetObject.sharesAvailable = totalValueShares - (dbAsset.totalValueShares - dbAsset.sharesAvailable);
        }

        if (this.state.settings!.oppIntegration && dbAsset.walletId) {
          if (updateAssetObject.name !== dbAsset.name || updateAssetObject.projectURL !== dbAsset.projectURL) {
            const [updateAssetWalletIdError] = await to(bloqifyFunctions.httpsCallable('updateWalletId')({
              merchant: dbAsset.walletId,
              metadata: {
                projectName: updateAssetObject.name || dbAsset.name,
                projectURL: updateAssetObject.projectURL || dbAsset.projectURL,
                assetId: assetRef.id,
              },
            }));
            if (updateAssetWalletIdError) {
              throw updateAssetWalletIdError;
            }
          }
        }

        if (!assetClone.emailAddressDummy && !dbAsset.emailAddressDummy) {
          updateAssetObject.emailAddressDummy = this.state.settings!.support_email.split('@').join(`+${dbAsset.customId}@`);
        }

        if (!assetClone.projectURL && !dbAsset.projectURL) {
          if (this.state.settings!.investorPortalEnabled) {
            updateAssetObject.projectURL = `${this.state.settings!.base_url}/property/${assetRef.id}`;
          }
        }

        transaction.update(assetRef, updateAssetObject);
      }));
      if (transactionError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: transactionError, operation: 'updateAsset' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, payload: asset, operation: 'updateAsset' });
    },
    async handlePublishAssetById(
      { commit },
      { assetId, published }: { assetId: string, published: boolean },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'handlePublishAssetById' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);

      const [updateAssetError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));
        if (getAssetError || !getAssetSuccess?.exists || getAssetSuccess.get('deleted')) {
          throw getAssetError || Error('Asset does not exist');
        }
        const asset = getAssetSuccess.data() as Asset;
        if (!assetChecks(asset, this.state.settings!.oppIntegration)) {
          throw Error('There are missing fields in the asset');
        }

        if (asset.psp && !asset.walletId) {
          throw Error('The asset type of funding requires an active wallet before publishing it');
        }

        const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

        transaction.update(
          assetRef,
          {
            published,
            updatedDateTime: serverTimestamp,
          },
        );
        transaction.set(
          bloqifyFirestore.collection('settings').doc('counts'),
          {
            publishedAssets: firebase.firestore.FieldValue.increment(published ? 1 : -1),
            updatedDateTime: serverTimestamp,
          },
          {
            merge: true,
          },
        );
      }));
      if (updateAssetError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: updateAssetError, operation: 'handlePublishAssetById' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'handlePublishAssetById' });
    },
    async notifyInvestors(
      { commit },
      { assetId, onlyInvestors }: { assetId: string, onlyInvestors: boolean },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'notifyInvestors' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const [getAssetError, getAsset] = await to(assetRef.get());
      if (getAssetError || !getAsset?.exists) {
        throw Error(getAssetError?.message || 'Asset does not exist');
      }

      const asset = getAsset!.data() as Asset;
      if (asset.deleted) {
        throw Error('Asset is deleted');
      }
      if (!asset.published) {
        throw Error('The asset must be published');
      }

      const [notifyPublishedAssetError] = await to(bloqifyFunctions.httpsCallable('notifyPublishedAsset')({ assetId, onlyInvestors }));
      if (notifyPublishedAssetError) {
        throw notifyPublishedAssetError;
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'notifyInvestors' });
    },
    async notifyByPushNotification(
      { commit },
      { assetId }: { assetId: string },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'sendFirebaseNotification' });
      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const [getAssetError, getAsset] = await to(assetRef.get());
      if (getAssetError || !getAsset?.exists) {
        throw Error(getAssetError?.message || 'Asset does not exist');
      }
      const asset = getAsset!.data() as Asset;
      if (asset.deleted) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: 'Asset is deleted', operation: 'sendFirebaseNotification' });
      }
      if (!asset.published) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: 'Asset must be published', operation: 'sendFirebaseNotification' });
      }

      const notificationTitle = i18n.t('notifications.title.newInvestmentTitle');
      const notificationBody = i18n.t('notifications.body.newInvestmentText');

      const [sendNotificationError] = await to(bloqifyFunctions.httpsCallable('sendFirebaseNotification')({
        assetId,
        title: notificationTitle,
        body: notificationBody,
        userGroup: 'allUsers',
      }));
      if (sendNotificationError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: sendNotificationError, operation: 'sendFirebaseNotification' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'sendFirebaseNotification' });
    },
    async handleHideAssetById(
      { commit },
      { assetId, hidden }: { assetId: string, hidden: boolean },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'handleHideAssetById' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const [updateAssetError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));
        if (getAssetError || !getAssetSuccess?.exists || getAssetSuccess.get('deleted')) {
          throw getAssetError || Error('Asset does not exist');
        }

        const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

        transaction.update(
          assetRef,
          {
            hidden,
            updatedDateTime: serverTimestamp,
          } as Asset,
        );
      }));
      if (updateAssetError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: updateAssetError, operation: 'handleHideAssetById' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'handleHideAssetById' });
    },
    async handleSendInvoicesById(
      { commit },
      { assetId, sendInvoices }: { assetId: string, sendInvoices: boolean },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'handleSendInvoicesById' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const [updateAssetError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));
        if (getAssetError || !getAssetSuccess?.exists || getAssetSuccess.get('deleted')) {
          throw getAssetError || Error('Asset does not exist');
        }

        const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

        transaction.update(
          assetRef,
          {
            sendInvoices,
            updatedDateTime: serverTimestamp,
          } as Asset,
        );
      }));
      if (updateAssetError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: updateAssetError, operation: 'handleSendInvoicesById' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'handleSendInvoicesById' });
    },
    async handleCreateInvoicesById(
      { commit },
      { assetId, createInvoices }: { assetId: string, createInvoices: boolean },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'handleCreateInvoicesById' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const [updateAssetError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));
        if (getAssetError || !getAssetSuccess?.exists || getAssetSuccess.get('deleted')) {
          throw getAssetError || Error('Asset does not exist');
        }

        const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

        transaction.update(
          assetRef,
          {
            createInvoices,
            updatedDateTime: serverTimestamp,
          } as Asset,
        );
      }));
      if (updateAssetError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: updateAssetError, operation: 'handleCreateInvoicesById' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'handleCreateInvoicesById' });
    },
    async handleActivateAssetById(
      { commit },
      { assetId, activated }: { assetId: string, activated: boolean },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'handleActivateAssetById' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      if (!activated) {
        throw Error('Can\'t deactivate a project');
      }

      const [updateAssetError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
        const [getAssetError, getAssetSuccess] = await to(transaction.get(assetRef));
        if (getAssetError || !getAssetSuccess?.exists || getAssetSuccess.get('deleted')) {
          throw getAssetError || Error('Asset does not exist');
        }
        const asset = getAssetSuccess.data() as Asset;
        if (!asset.published) {
          throw Error('The asset must be published before been able to activate it');
        }
        if (asset.currentValueEuro !== asset.totalValueEuro) {
          throw Error('The asset must be fully funded in order to be activated');
        }
        if ((asset.paymentMethod || AssetPaymentMethod.None) !== AssetPaymentMethod.None) {
          if (!asset.walletId) {
            throw Error('The asset payment method requires an active wallet');
          }
          if (asset.paymentMethod === AssetPaymentMethod.Mandate) {
            if (!asset.mandateId || asset.mandateStatus !== MandateStatus.completed) {
              throw Error('The asset payment method requires an active mandate');
            }
          }
        }

        const serverTimestamp = firebase.firestore.FieldValue.serverTimestamp();

        transaction.update(
          assetRef,
          {
            activated,
            updatedDateTime: serverTimestamp,
          },
        );
      }));
      if (updateAssetError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: updateAssetError, operation: 'handleActivateAssetById' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'handleActivateAssetById' });
    },
    async handleDeleteAssetById(
      { commit },
      { assetId }: { assetId: string },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'handleDeleteAssetById' });

      const [updateAssetError] = await to(bloqifyFirestore.collection('assets').doc(assetId).update(
        {
          deleted: true,
          updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
        },
      ));
      if (updateAssetError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: updateAssetError, operation: 'handleDeleteAssetById' });
      }

      const [deleteCollectionsError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const assetRef = bloqifyFirestore.collection('assets').doc(assetId);

        // Update Asset subCollections
        const assetSubCollections = ['interestRates', 'invoices', 'repayments', 'manualInvoices', 'endDates', 'interestRateTypes'];
        const [assetSubCollectionError] = await to(Promise.all(assetSubCollections.map(async (collectionName): Promise<void> => {
          const subCollectionRef = assetRef.collection(collectionName);
          const [getSubCollectionError, getSubCollectionSuccess] = await to(subCollectionRef.get());

          if (getSubCollectionError) {
            throw getSubCollectionError;
          }

          getSubCollectionSuccess!.docs.forEach((subCollectionDoc): void => {
            transaction.update(subCollectionDoc.ref, {
              deleted: true,
              updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
            });
          });
        })));
        if (assetSubCollectionError) {
          throw assetSubCollectionError;
        }

        // Update investments
        const [getInvestmentError, getInvestmentSuccess] = await to(bloqifyFirestore.collection('investments').where('asset', '==', assetRef).get());

        if (getInvestmentError) {
          throw getInvestmentError;
        }

        const [investmentUpdateError] = await to(Promise.all(getInvestmentSuccess!.docs.map(async (investment): Promise<void> => {
          transaction.update(investment.ref, {
            deleted: true,
            updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
          });

          // Update Investment subCollections
          const investmentSubCollections = ['invoices', 'payments', 'repayments'];
          const [investmentSubCollectionError] = await to(Promise.all(investmentSubCollections.map(async (collectionName): Promise<void> => {
            const subCollectionRef = investment.ref.collection(collectionName);
            const [getSubCollectionError, getSubCollectionSuccess] = await to(subCollectionRef.get());

            if (getSubCollectionError) {
             throw getSubCollectionError;
            }

            getSubCollectionSuccess!.docs.forEach((subCollectionDoc): void => {
              transaction.update(subCollectionDoc.ref, {
                deleted: true,
                updatedDateTime: firebase.firestore.FieldValue.serverTimestamp(),
              });
            });
          })));
          if (investmentSubCollectionError) {
            throw investmentSubCollectionError;
          }
        })));
        if (investmentUpdateError) {
          throw investmentUpdateError;
        }
      }));
      if (deleteCollectionsError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: deleteCollectionsError, operation: 'handleDeleteAssetById' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'handleDeleteAssetById' });
    },
    async createManualInvoice(
      { commit },
      data: CreateManualInvoiceParam,
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'createManualInvoice' });

      const [createManualInvoiceError] = await to(bloqifyFunctions.httpsCallable('generateManualInvoice')(data));

      if (createManualInvoiceError) {
        return commit(SET_ASSET, {
          status: DataContainerStatus.Error,
          operation: 'createManualInvoice',
          payload: createManualInvoiceError,
          error: createManualInvoiceError.message,
        });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'createManualInvoice' });
    },
    async deleteManualInvoice(
      { commit },
      { assetId, invoiceId }: InvoiceActionParam,
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'deleteManualInvoice' });

      // Call BQ function to innitiate process, checks will be performed there
      const [generateInvoicesError] = await to(bloqifyFunctions.httpsCallable('financialsOperations')({
        assetId,
        docId: invoiceId,
        object: 'manualInvoice',
        action: 'delete',
      }));
      if (generateInvoicesError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: generateInvoicesError, operation: 'deleteManualInvoice' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'deleteManualInvoice' });
    },
    async sendManualInvoice(
      { commit },
      { assetId, invoiceId }: InvoiceActionParam,
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'sendManualInvoice' });

      const [generateInvoicesError] = await to(bloqifyFunctions.httpsCallable('sendManualInvoice')({ assetId, invoiceId }));
      if (generateInvoicesError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: generateInvoicesError, operation: 'sendManualInvoice' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'sendManualInvoice' });
    },
    async markManualInvoiceAsPaid(
      { commit },
      { assetId, invoiceId }: InvoiceActionParam,
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'markManualInvoiceAsPaid' });

      // Call BQ function to innitiate process, checks will be performed there
      const [generateInvoicesError] = await to(bloqifyFunctions.httpsCallable('financialsOperations')({
        assetId,
        docId: invoiceId,
        object: 'manualInvoice',
        action: 'markAsPaid',
      }));
      if (generateInvoicesError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: generateInvoicesError, operation: 'markManualInvoiceAsPaid' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'markManualInvoiceAsPaid' });
    },
    async payFromDepositManualInvoice(
      { commit },
      { assetId, invoiceId, profile }: InvoiceActionParam,
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'payFromDepositManualInvoice' });

      // Call BQ function to innitiate process, checks will be performed there
      const [generateInvoicesError] = await to(bloqifyFunctions.httpsCallable('financialsOperations')({
        assetId,
        docId: invoiceId,
        object: 'manualInvoice',
        action: 'payFromDeposit',
        profile,
      }));
      if (generateInvoicesError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: generateInvoicesError, operation: 'payFromDepositManualInvoice' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'payFromDepositManualInvoice' });
    },
    async createAutomatedInvoice(
      { commit },
      { assetId, time, proForma, prevPeriodsTimes }: CreateAutomatedInvoiceParam,
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'createAutomatedInvoice' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const [getAssetError, getAssetSuccess] = await to(assetRef.get());
      if (getAssetError || !getAssetSuccess?.exists) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: 'Error retrieving the asset.', operation: 'createAutomatedInvoice' });
      }
      const asset = getAssetSuccess.data() as Asset;
      if (!asset.activated) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: 'Can\'t create new invoices until the project is activated', operation: 'createAutomatedInvoice' });
      }

      if (prevPeriodsTimes?.length) {
        for (let i = 0; i < prevPeriodsTimes.length; i++) {
          // eslint-disable-next-line no-await-in-loop
          const [generateInvoicesError, generateInvoicesResult] = await to(bloqifyFunctions.httpsCallable('reGenerateInvoice')({ assetId, time: prevPeriodsTimes[i], proForma: false }));
          if (generateInvoicesError) {
            return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: generateInvoicesError, operation: 'createAutomatedInvoice' });
          }
          if (generateInvoicesResult!.data.invoices === 0) {
            return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: 'Invoice wasn\'t generated. Check selected period', operation: 'createAutomatedInvoice' });
          }
        }
      }

      const [generateInvoicesError, generateInvoicesResult] = await to(bloqifyFunctions.httpsCallable('reGenerateInvoice')({ assetId, time, proForma }));
      if (generateInvoicesError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: generateInvoicesError, operation: 'createAutomatedInvoice' });
      }
      if (generateInvoicesResult!.data.invoices === 0) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: 'Invoice wasn\'t generated. Check selected period', operation: 'createAutomatedInvoice' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'createAutomatedInvoice' });
    },
    async sendAutomatedInvoice(
      { commit },
      { assetId, invoiceId }: InvoiceActionParam,
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'sendAutomatedInvoice' });

      const [generateInvoicesError] = await to(bloqifyFunctions.httpsCallable('reSendInvoice')({ assetId, invoiceId }));
      if (generateInvoicesError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: generateInvoicesError, operation: 'sendAutomatedInvoice' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'sendAutomatedInvoice' });
    },
    async markAutomatedInvoiceAsPaid(
      { commit },
      { assetId, invoiceId }: InvoiceActionParam,
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'markAutomatedInvoiceAsPaid' });

      // Call BQ function to innitiate process, checks will be performed there
      const [generateInvoicesError] = await to(bloqifyFunctions.httpsCallable('financialsOperations')({
        assetId,
        docId: invoiceId,
        object: 'invoice',
        action: 'markAsPaid',
      }));
      if (generateInvoicesError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: generateInvoicesError, operation: 'markAutomatedInvoiceAsPaid' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'markAutomatedInvoiceAsPaid' });
    },
    async deleteAutomatedInvoice(
      { commit },
      { assetId, invoiceId }: InvoiceActionParam,
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'deleteAutomatedInvoice' });

      // Call BQ function to innitiate process, checks will be performed there
      const [generateInvoicesError] = await to(bloqifyFunctions.httpsCallable('financialsOperations')({
        assetId,
        docId: invoiceId,
        object: 'invoice',
        action: 'delete',
      }));
      if (generateInvoicesError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: generateInvoicesError, operation: 'deleteAutomatedInvoice' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'deleteAutomatedInvoice' });
    },
    async payFromDepositAutomatedInvoice(
      { commit },
      { assetId, invoiceId, profile }: InvoiceActionParam,
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'payFromDepositAutomatedInvoice' });

      // Call BQ function to innitiate process, checks will be performed there
      const [generateInvoicesError] = await to(bloqifyFunctions.httpsCallable('financialsOperations')({
        assetId,
        docId: invoiceId,
        object: 'invoice',
        action: 'payFromDeposit',
        profile,
      }));
      if (generateInvoicesError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: generateInvoicesError, operation: 'payFromDepositAutomatedInvoice' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'payFromDepositAutomatedInvoice' });
    },
    async requestAssetPayments(
      { commit },
      { assetId }: { assetId: string },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'requestAssetPayments' });

      const [requestPaymentsError] = await to(bloqifyFunctions.httpsCallable('requestPayment')({ mode: 'asset', assetId }));
      if (requestPaymentsError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: requestPaymentsError, operation: 'requestAssetPayments' });
      }

      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'requestAssetPayments' });
    },
    async requestWalletId (
      { commit },
      { assetId }: { assetId: string },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'requestWalletId' });

      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);
      const [getAssetError, getAsset] = await to(assetRef.get());
      if (getAssetError || !getAsset?.exists) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: Error(getAssetError?.message || 'Asset not found'), operation: 'requestWalletId' });
      }
      const asset = getAsset!.data() as Asset;

      const [requestWalletIdError, requestWalletId] = await to(bloqifyFunctions.httpsCallable('createAssetWalletId')({
        projectName: asset.name, emailAddressDummy: asset.emailAddressDummy, projectURL: asset.projectURL, assetId,
      }));

      if (requestWalletIdError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: requestWalletIdError, operation: 'requestWalletId' });
      }
      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'requestWalletId', payload: requestWalletId!.data.walletId });
    },
    async requestMandateId (
      { commit },
      { assetId }: { assetId: string },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'requestMandateId' });

      const [requestMandateIdError, requestMandateId] = await to(bloqifyFunctions.httpsCallable('createAssetMandateId')({
        assetId,
      }));

      if (requestMandateIdError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: requestMandateIdError, operation: 'requestWalletId' });
      }
      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'requestMandateId', payload: requestMandateId!.data });
    },
    async sendMandateEmail (
      { commit },
      { assetId }: { assetId: string },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'sendMandateEmail' });

      const [sendMandateEmailError, sendMandateEmailSuccess] = await to(bloqifyFunctions.httpsCallable('mandateAction')({
        assetId,
        action: 'email',
      }));
      if (sendMandateEmailError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: sendMandateEmailError, operation: 'sendMandateEmail' });
      }
      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'sendMandateEmail', payload: sendMandateEmailSuccess!.data });
    },
    async revokeMandate (
      { commit },
      { assetId }: { assetId: string },
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'revokeMandate' });

      const [revokeMandateError, revokeMandateSuccess] = await to(bloqifyFunctions.httpsCallable('mandateAction')({
        assetId,
        action: 'revoke',
      }));
      if (revokeMandateError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: revokeMandateError, operation: 'revokeMandate' });
      }
      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'revokeMandate', payload: revokeMandateSuccess!.data });
    },
    async setInvoicePlanning (
      { commit },
      { assetId, ...rest }: SetInvoicePlanningParam,
    ): Promise<void> {
      commit(SET_ASSET, { status: DataContainerStatus.Processing, operation: 'setInvoicePlanning' });
      const assetRef = bloqifyFirestore.collection('assets').doc(assetId);

      const [updateAssetError] = await to(assetRef.update(rest));
      if (updateAssetError) {
        return commit(SET_ASSET, { status: DataContainerStatus.Error, payload: updateAssetError, operation: 'setInvoicePlanning' });
      }
      return commit(SET_ASSET, { status: DataContainerStatus.Success, operation: 'setInvoicePlanning' });
    },
  },
  getters: {},
};
