import firebase from 'firebase/app';
import { Investor } from '../users/User';
import { Asset, InterestRateTypes } from './Asset';
import { Investment } from '../investments/Investment';
// eslint-disable-next-line import/no-cycle
import { AssetRepayment, InvestmentRepayment } from './Repayments';
import { EffectiveFee, EffectiveWhOrEe } from './Fees';

export enum InvoicePaymentStatus {
  Pending = 'pending',
  'In-progress' = 'in-progress',
  Paid = 'paid',
  Error = 'error',
  Refunded = 'refunded',
  Failed = 'failed',
}

export enum InvoiceStatus {
  Waiting = 'waiting',
  Sent = 'sent',
  'Not-sent' = 'not-sent'
}

export enum InvoiceOptions {
  Mortgage = 'mortgage',
  DirectAmount = 'direct amount',
}

export interface AssetInvoice {
  id?: string;
  deleted: boolean;
  type: InterestRateTypes;
  totalDividendAmount: number; // Total cost of this invoice, contains repayments, interest and fees
  totalInterestAmount: number; // Interest of the invoice, contains managementFee
  totalLoantakerFeeAmount: number;
  totalLoantakerFeeVatAmount: number;
  totalInvestorFeeAmount: number;
  totalInvestorFeeVatAmount: number;
  totalInvestorWithholdingAmount: number;
  totalInvestorExternalEarningAmount: number;
  fees: EffectiveFee[];
  withholdings: EffectiveWhOrEe[];
  externalEarnings: EffectiveWhOrEe[];
  interestRate: number;
  status: InvoiceStatus;
  paymentStatus: InvoicePaymentStatus;
  document: string;
  asset: firebase.firestore.DocumentReference | Asset;
  repayment?: firebase.firestore.DocumentReference | AssetRepayment;
  createdDateTime: firebase.firestore.Timestamp;
  period: {
    start: firebase.firestore.Timestamp;
    end: firebase.firestore.Timestamp;
  };
  periodNum: number;
  transactionUid?: string[];
  sendDateTime?: firebase.firestore.Timestamp;
  paymentDateTime?: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
}

export interface ManualInvoice {
  id?: string;
  title: string;
  subject: string;
  amount: number;
  provision?: number;
  status: InvoicePaymentStatus;
  transactionUid?: string[];
  document: string;
  deleted: boolean,
  date: firebase.firestore.Timestamp;
  expirationDate: firebase.firestore.Timestamp;
  createdDateTime: firebase.firestore.Timestamp;
  paymentDateTime?: firebase.firestore.Timestamp;
  updatedDateTime?: firebase.firestore.Timestamp;
  vatAmount?: number;
  vatFormula: 'noVat' | 'included' | 'added';
}

export interface InvestmentInvoiceParent {
  id?: string;
  deleted: boolean;
  createdDateTime: firebase.firestore.Timestamp;
  updatedDateTime: firebase.firestore.Timestamp;
  type: 'period' | 'manual' | 'repayment';
  amount: number;
  additionalCents?: number;
  fees?: EffectiveFee[];
  feeAmount: number;
  feeVatAmount: number;
  withholdings?: EffectiveWhOrEe[];
  withholdingAmount: number;
  externalEarnings?: EffectiveWhOrEe[];
  externalEarningAmount: number;
  investment: firebase.firestore.DocumentReference | Investment;
  investor: firebase.firestore.DocumentReference | Investor;
  paymentStatus: InvoicePaymentStatus;
  paymentDateTime?: firebase.firestore.Timestamp;
}

export interface InvestmentPeriodInvoice extends InvestmentInvoiceParent {
  type: 'period';
  assetInvoice: firebase.firestore.DocumentReference | AssetInvoice;
  repayment?: firebase.firestore.DocumentReference | InvestmentRepayment;
  period: {
    start: firebase.firestore.Timestamp;
    end: firebase.firestore.Timestamp;
  };
}

export interface InvestmentManualInvoice extends InvestmentInvoiceParent {
  type: 'manual';
  assetManualInvoice: firebase.firestore.DocumentReference | ManualInvoice;
  title: string;
  expirationDate: firebase.firestore.Timestamp;
}

export interface InvestmentRepaymentInvoice extends InvestmentInvoiceParent {
  type: 'repayment';
  reason: 'missingInterest' | 'lastPeriod',
  assetInvoice: firebase.firestore.DocumentReference | AssetInvoice;
  repayment: firebase.firestore.DocumentReference | InvestmentRepayment;
}

export type InvestmentInvoice = InvestmentPeriodInvoice | InvestmentManualInvoice | InvestmentRepaymentInvoice;
