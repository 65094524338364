import * as SparkMD5 from 'spark-md5';
import { bloqifyStorage } from '@/boot/firebase';
import { parse } from 'json2csv';
import FileSaver from 'file-saver';
import XLSX from 'xlsx';

/**
 * Function that generates the md5 hash of a file incrementally (by chunks)
 */
export const generateFileMd5Hash = async (file: File, transformToBase64?: boolean): Promise<string> => (
  new Promise((resolve, reject): void => {
    const chunkSize = 2097152; // Read in chunks of 2MB
    const spark = new SparkMD5.ArrayBuffer();
    const fileReader = new FileReader();
    const chunks = Math.ceil(file.size / chunkSize);

    let currentChunk = 0;

    fileReader.onerror = (): void => {
      reject('MD5 computation failed - error reading the file');
    };

    // read chunk starting at `cursor` into memory
    const loadNext = (): void => {
      const start = currentChunk * chunkSize;
      const end = ((start + chunkSize) >= file.size) ? file.size : start + chunkSize;

      fileReader.readAsArrayBuffer(file.slice(start, end));
    };

    // when it's available in memory, process it
    // If using TS >= 3.6, you can use `FileReaderProgressEvent` type instead
    // of `any` for `e` variable, otherwise stick with `any`
    // See https://github.com/Microsoft/TypeScript/issues/25510
    fileReader.onload = (e: any): void => {
      spark.append(e.target.result); // Append array buffer
      currentChunk++;

      if (currentChunk < chunks) {
        loadNext();
      } else {
        resolve(transformToBase64 ? btoa(spark.end()) : spark.end());
      }
    };

    loadNext();
  })
);

/**
 * Function that checks if the file is already in Google Storage
 */
export const checkFileInStorage = async (storagePath: string, generatedMd5Hash: string): Promise<boolean> => {
  const storageRef = bloqifyStorage.ref();
  const fileMetadata = await storageRef.child(storagePath).getMetadata();

  const { md5Hash, customMetadata: { md5Hash: customMd5Hash } } = fileMetadata;
  if (!md5Hash && !customMd5Hash) {
    throw Error('There was a problem retrieving the hash.');
  }

  return generatedMd5Hash === md5Hash || generatedMd5Hash === customMd5Hash;
};

/**
 * Function that saves required data into an excel file, with the required format
 */
export const saveToExcelFile = (type: 'excel' | 'csv', fileName: string, data: any[]): void => {
  switch (type) {
    case 'csv': {
      const BOM = '\uFEFF';
      const csv = BOM + parse(data);
      const blob = new Blob([csv], { type: 'text/csv;charset=utf-8' });
      FileSaver.saveAs(blob, `${fileName}.csv`);
      break;
    }
    case 'excel': {
      const worksheet = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      XLSX.utils.book_append_sheet(workbook, worksheet);
      XLSX.writeFile(workbook, `${fileName}.xlsx`);
      break;
    }
    default: {
      throw Error('Wrong option');
    }
  }
};
