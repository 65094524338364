import { Module } from 'vuex';
import to from 'await-to-js';
import { State } from '@/models/State';
import { bloqifyFirestore, bloqifyFunctions, firebase } from '@/boot/firebase';
import { Asset, InvoiceFrequency } from '@/models/assets/Asset';
import { DataContainerStatus } from '@/models/Common';
import { AssetEndDate, EndDateStatus } from '@/models/assets/EndDates';
import { periodCheck } from '../utils/checks';
import { CustomBatch } from '../utils/customBatch';
import { Vertebra, generateState, mutateState } from '../utils/skeleton';

export interface DeleteEndDateParam {
  assetId: string,
  endDateId: string,
}

export interface AddEndDateParam {
  assetId: string;
  endDate: Date;
  date: Date;
  numberOfPeriods: number;
  invoiceFrequency: InvoiceFrequency;
  sendPushNotification?: boolean;
  notificationTitle?: string;
  notificationBody?: string;
}

const SET_ENDDATE = 'SET_ENDDATE';

export default <Module<Vertebra, State>> {
  state: generateState(),
  mutations: {
    [SET_ENDDATE](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async addEndDate(
      { commit },
      { assetId, endDate, date, numberOfPeriods, invoiceFrequency, sendPushNotification, notificationTitle, notificationBody }: AddEndDateParam,
    ): Promise<void> {
      commit(SET_ENDDATE, { status: DataContainerStatus.Processing, operation: 'addEndDate' });
      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const transactionBatch = new CustomBatch(transaction);
        const now = new Date();
        const futureDate = now < date;

        const [getAssetError, getAsset] = await to(transaction.get(bloqifyFirestore.collection('assets').doc(assetId)));
        if (getAssetError || !getAsset || !getAsset.exists) {
          throw Error(getAssetError?.message || 'Asset not found');
        }
        const asset = getAsset.data() as Asset;

        const [checkError, checkResult] = await to(periodCheck(assetId, date.valueOf()));
        if (checkError) {
          throw checkError;
        }
        if (!checkResult) {
          throw Error('Can`t change asset financials for already generated periods');
        }

        const [checkExistError, checkExist] = await to(getAsset.ref.collection('endDates')
          .where('deleted', '==', false)
          .where('appliedDateTime', '==', date)
          .get());
        if (checkExistError) {
          throw Error(checkExistError.message);
        }
        if (!checkExist!.empty) {
          throw Error('There is already an loan duration change planned for that period');
        }

        const endDateRef = getAsset.ref.collection('endDates').doc();
        const data: AssetEndDate = {
          asset: getAsset.ref,
          deleted: false,
          endDate: firebase.firestore.Timestamp.fromDate(endDate),
          numberOfPeriods,
          invoiceFrequency,
          status: futureDate ? EndDateStatus.Pending : EndDateStatus.Applied,
          appliedDateTime: firebase.firestore.Timestamp.fromDate(date),
          createdDateTime: firebase.firestore.Timestamp.fromDate(now),
          updatedDateTime: firebase.firestore.Timestamp.fromDate(now),
        };

        transactionBatch.addOperation({
          ref: endDateRef,
          data,
        });

        const [getAllEndDatesError, getAllEndDates] = await to((getAsset.ref.collection('endDates')
          .where('deleted', '==', false)
          .where('status', '==', EndDateStatus.Applied)
          .orderBy('appliedDateTime', 'desc')
          .get()));

        if (getAllEndDatesError) {
          throw Error(getAllEndDatesError.message);
        }

        const recentDate = date.valueOf() >= (getAllEndDates?.docs[0]?.get('appliedDateTime')?.toDate().valueOf() || -Infinity);

        if (!futureDate && recentDate) {
          transactionBatch.addOperation({
            ref: getAsset.ref,
            data: {
              endDateTime: firebase.firestore.Timestamp.fromDate(endDate),
              numberOfPeriods,
              invoiceFrequency,
              updatedDateTime: firebase.firestore.Timestamp.fromDate(now),
            } as Asset,
          });
        }

        transactionBatch.commit();
      }));
      if (transactionError) {
        return commit(SET_ENDDATE, { status: DataContainerStatus.Error, payload: transactionError, operation: 'addEndDate' });
      }

      // If push notification checkbox is selected
      if (sendPushNotification) {
        const [notifyError] = await to(bloqifyFunctions.httpsCallable('sendFirebaseNotification')({
          title: notificationTitle,
          body: notificationBody,
          userGroup: 'projectInvestors',
          assetId,
        }));
        if (notifyError) {
          return commit(SET_ENDDATE, { status: DataContainerStatus.Error, payload: notifyError, operation: 'addEndDate' });
        }
      }
      return commit(SET_ENDDATE, { status: DataContainerStatus.Success, operation: 'addEndDate' });
    },
    async deleteEndDate(
      { commit },
      { assetId, endDateId }: DeleteEndDateParam,
    ): Promise<void> {
      commit(SET_ENDDATE, { status: DataContainerStatus.Processing, operation: 'deleteEndDate' });
      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const transactionBatch = new CustomBatch(transaction);
        const now = new Date();
        if (endDateId === 'initial') {
          throw Error('Can\'t delete initial end date of the project');
        }

        const [getAssetError, getAsset] = await to(transaction.get(bloqifyFirestore.collection('assets').doc(assetId)));
        if (getAssetError || !getAsset || !getAsset.exists) {
          throw Error(getAssetError?.message || 'Asset not found');
        }

        const [getEndDateError, getEndDate] = await to(transaction.get(getAsset.ref.collection('endDates').doc(endDateId)));
        if (getEndDateError || !getEndDate || !getEndDate.exists) {
          throw Error(getEndDateError?.message || 'End date not found');
        }
        const endDate = getEndDate.data() as AssetEndDate;

        if (endDate.deleted) {
          throw Error('End date already erased');
        }

        const [checkError, checkResult] = await to(periodCheck(assetId, endDate.appliedDateTime.toMillis()));
        if (checkError) {
          throw checkError;
        }
        if (!checkResult) {
          throw Error('Can`t change asset financials for already generated periods');
        }

        transactionBatch.addOperation({
          ref: getEndDate.ref,
          data: {
            deleted: true,
            updatedDateTime: firebase.firestore.Timestamp.fromDate(now),
          } as AssetEndDate,
        });

        const [getAllEndDatesError, getAllEndDates] = await to(getAsset.ref.collection('endDates')
          .where('deleted', '==', false)
          .where('status', '==', EndDateStatus.Applied)
          .orderBy('appliedDateTime', 'desc')
          .get());

        if (getAllEndDatesError) {
          throw Error(getAllEndDatesError.message);
        }

        const lastEndDate = getAllEndDates!.docs[0].id === getEndDate.id;

        if (lastEndDate) {
          if (getAllEndDates!.size <= 1) {
            throw Error('Not previous end date found');
          }
          const previousEndDate = getAllEndDates!.docs[1].data() as AssetEndDate;

          transactionBatch.addOperation({
            ref: getAsset.ref,
            data: {
              endDateTime: previousEndDate.endDate,
              numberOfPeriods: previousEndDate.numberOfPeriods,
              invoiceFrequency: previousEndDate.invoiceFrequency,
              updatedDateTime: firebase.firestore.Timestamp.fromDate(now),
            } as Asset,
          });
        }

        transactionBatch.commit();
      }));
      if (transactionError) {
        return commit(SET_ENDDATE, { status: DataContainerStatus.Error, payload: transactionError, operation: 'deleteEndDate' });
      }
      return commit(SET_ENDDATE, { status: DataContainerStatus.Success, operation: 'deleteEndDate' });
    },
  },
};
