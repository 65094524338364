import { Module } from 'vuex';
import to from 'await-to-js';
import moment from 'moment';
import BigNumber from 'bignumber.js';
import { State } from '@/models/State';
import { bloqifyFirestore, firebase, bloqifyFunctions } from '@/boot/firebase';
import { DataContainerStatus } from '@/models/Common';
import { Asset } from '@/models/assets/Asset';
import { Investor } from '@/models/users/User';
import { AssetPaymentMethod } from '@/models/opp/Opp';
import { PaymentStatus, PaymentProvider, Investment, Payment } from '@/models/investments/Investment';
import { Vertebra, generateState, mutateState } from '@/store/utils/skeleton';
import { SetupFee } from '@/models/assets/Fees';
import { assetChecks } from './asset';
import { periodCheck } from '../utils/checks';
import { removeDocReferences } from '../utils/references';

const SET_PAYMENT = 'SET_PAYMENT';

// Payload types
export type CreatePaymentPayload = {
  /** € */
  amount: number,
  assetId: string,
  investorId: string,
  paymentDateTime?: Date,
  dividendsFormat?: Payment['dividendsFormat'],
  endDateTime?: number,
  managementFee?: number,
  proForma?: boolean,
  type?: string,
  sendEmail: boolean,
}

export type SendSharesEmailInput = {
  investor: Investor,
  asset: Asset,
}

export type UpdatePaymentPayload = CreatePaymentPayload & {
  investmentId: string,
  paymentId: string,
}

export default <Module<Vertebra, State>> {
  state: generateState(),
  mutations: {
    [SET_PAYMENT](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async createPayment(
      { commit },
      {
        amount,
        assetId,
        investorId,
        paymentDateTime,
        dividendsFormat,
        endDateTime: paymentEndDateTime,
        managementFee,
        proForma,
        type,
        sendEmail,
      }: CreatePaymentPayload,
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'createPayment' });

      const [transactionError, transactionSuccess] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<SendSharesEmailInput & { investmentId: string, paymentId: string }> => {
          // Collection references
          const insertedAssetId = bloqifyFirestore.collection('assets').doc(assetId);
          const investmentsRef = bloqifyFirestore.collection('investments');
          const investorRef = bloqifyFirestore.collection('investors').doc(investorId);
          let investmentRef = investmentsRef.doc();
          let paymentRef = investmentRef.collection('payments').doc();

          const [readAssetError, readAssetSuccess] = await to(transaction.get(insertedAssetId));
          if (readAssetError || !readAssetSuccess?.exists) {
            throw readAssetError || Error('Asset not found.');
          }

          const asset = readAssetSuccess!.data() as Asset;
          const {
            sharePrice, euroMin, sharesAvailable, endDateTime,
            returnsAfterEnd, fixedDividends, startDateTime, premium,
          } = asset;

          if (!assetChecks(asset, this.state.settings!.oppIntegration)) {
            throw Error('The asset you are trying to add the payment to has invalid fields.');
          }

          // Paid payment checks
          if (!proForma) {
            const [checkError, checkResult] = await to(periodCheck(assetId, paymentDateTime!.valueOf()));
            if (checkError) {
              throw checkError;
            }
            if (!checkResult) {
              throw Error('Can`t change asset financials for already generated periods');
            }

            if (startDateTime && firebase.firestore.Timestamp.fromMillis(paymentDateTime!.valueOf()) < startDateTime) {
              throw Error('The date of the payment cannot be earlier than the start date of the selected asset');
            }
          }

          const [readInvestorError, readInvestorSuccess] = await to(transaction.get(investorRef));
          if (readInvestorError || !readInvestorSuccess?.exists) {
            throw readInvestorError || Error('Investor not found.');
          }
          const investor = readInvestorSuccess!.data() as Investor;

          if (this.state.settings!.oppIntegration) {
            if ((asset.paymentMethod || AssetPaymentMethod.None) !== AssetPaymentMethod.None) {
              // Check investor walletId
              if (!investor.walletId) {
                throw Error('To create a payment the investor must have a wallet in OPP');
              }
              // Check asset walletId
              if (!asset.walletId) {
                throw Error('To create a payment the asset must have a wallet in OPP');
              }
            }
          }

          if (new BigNumber(amount).mod(sharePrice).toNumber() !== 0) {
            throw Error(`The amount has to be a total of shares bought (price per share: ${sharePrice} eur).`);
          }

          const sharesAmount = new BigNumber(amount).dividedBy(sharePrice).toNumber();
          if (sharesAmount > sharesAvailable) {
            throw Error('The asset does not have this many available shares.');
          }

          // Client side we cannot use transaction to get DocumentQueries
          const [getInvestmentError, getInvestmentSuccess] = await to(
            investmentsRef.where('asset', '==', insertedAssetId)
            .where('investor', '==', investorRef)
            .where('deleted', '==', false)
            .get(),
          );
          if (getInvestmentError) {
            throw getInvestmentError;
          }

          const foundInvestments = !getInvestmentSuccess?.empty;
          const investmentDoc = foundInvestments ? getInvestmentSuccess!.docs[0] : undefined;
          let firstInvestment = false;

          if (foundInvestments) {
            investmentRef = investmentDoc!.ref;
            const [getPaymentsError, getPaymentsSuccess] = await to(
              investmentRef.collection('payments').get(),
            );
            if (getPaymentsError) {
              throw getPaymentsError;
            }
            if (getPaymentsSuccess!.empty) {
              throw Error('There was an error retrieving previous investments information.');
            }
            firstInvestment = !getPaymentsSuccess!.docs.some((snap): boolean => snap.get('providerData.status') === PaymentStatus.Paid);

            // Reset paymentRef
            paymentRef = investmentRef.collection('payments').doc();
          }

          // Only check the minimum amount if it is the first investment
          if (((foundInvestments && firstInvestment) || !foundInvestments) && amount < euroMin) {
            throw Error(`The amount has to be higher than the minimum (${euroMin} eur).`);
          }

          let years = 0;
          if (fixedDividends) {
            const numberedEndDateTime = endDateTime?.toMillis();
            // The paymentDateTime should already be in milliseconds as it comes from the frontend
            const paymentDateFormatted = moment(paymentDateTime);
            const yearsTemp = moment(numberedEndDateTime).diff(paymentDateFormatted, 'years');

            // If no years remaining, put 1
            years = yearsTemp > 0 ? yearsTemp : 1;
          } else if (!premium && !dividendsFormat) {
            throw Error('Missing dividends format.');
          }

          const dateNow = firebase.firestore.Timestamp.now();

          // Funding amount
          const euroAmount = Number(amount);
          // Emission cost, fee added to funding amount
          const emissionCost = new BigNumber(euroAmount)
            .multipliedBy(asset.emissionCost || 0)
            .dividedBy(100)
            .dp(2, BigNumber.ROUND_UP)
            .toNumber();
          // Total Amount, funding amount + emssion cost
          const totalAmount = new BigNumber(euroAmount)
            .plus(emissionCost)
            .dp(2, BigNumber.ROUND_UP)
            .toNumber();
          // App Fee, amount deducted of the funding amount
          const appFee = new BigNumber(euroAmount)
            .multipliedBy(asset.applicationFee || 0)
            .dividedBy(100)
            .dp(2, BigNumber.ROUND_UP)
            .toNumber();
          // Total Fees, amount to be deducted from the payment amount
          const feeAmount = new BigNumber(emissionCost)
            .plus(appFee)
            .dp(2, BigNumber.ROUND_UP)
            .toNumber();

          const newDividensFormat: [string, number] | undefined = !asset.premium && asset.fixedDividends
            ? [years.toString(), returnsAfterEnd || 0] : dividendsFormat;

          if (newDividensFormat === undefined) {
            throw Error('Missing dividends format.');
          }

          const newPayment: Payment = {
            asset: insertedAssetId,
            investment: investmentRef,
            investor: investorRef,
            createdDateTime: dateNow,
            ...(!proForma && { paymentDateTime: firebase.firestore.Timestamp.fromMillis(paymentDateTime!.valueOf()) }),
            id: paymentRef.id,
            provider: PaymentProvider.Custom,
            updatedDateTime: dateNow,
            deleted: false,
            dividendsFormat: newDividensFormat,
            providerData: {
              amount: {
                currency: 'EUR',
                value: totalAmount.toFixed(2),
                totalAmount,
                feeAmount,
              },
              metadata: {
                uid: investorId,
                euroAmount,
                sharesAmount,
                investmentId: investmentRef.id,
                assetId: insertedAssetId.id,
                paymentId: paymentRef.id,
              },
              status: proForma ? PaymentStatus.Open : PaymentStatus.Paid,
            },
            ...(paymentEndDateTime && {
              endDateTime: firebase.firestore.Timestamp.fromMillis(
                paymentEndDateTime,
              ),
            }),
            ...(type && { type }),
          };

          // Setting new investment or updating old one
          if (!foundInvestments) {
            const investment: Omit<Investment, 'id'> = {
              asset: insertedAssetId,
              createdDateTime: dateNow,
              updatedDateTime: dateNow,
              investor: investorRef,
              ...(!proForma ? {
                paidPayments: 1,
                paidEuroTotal: euroAmount,
                boughtSharesTotal: sharesAmount,
              } : {
                openPayments: 1,
                paidEuroTotal: 0,
                openEuroTotal: euroAmount,
                boughtSharesTotal: 0,
                openSharesTotal: sharesAmount,
              }),
              hasOpenPayments: !!proForma,
              managementFee: managementFee || 0,
              deleted: false,
            };

            transaction.set(investmentRef, investment);
          } else {
            const investment = investmentDoc!.data() as Investment;
            transaction.update(
              investmentRef,
              {
                ...(!proForma && {
                  paidEuroTotal: new BigNumber(investment.paidEuroTotal || 0).plus(euroAmount).toNumber(),
                  boughtSharesTotal: new BigNumber(investment.boughtSharesTotal || 0).plus(sharesAmount).toNumber(),
                  paidPayments: firebase.firestore.FieldValue.increment(1),
                }),
                ...(proForma && {
                  openPayments: firebase.firestore.FieldValue.increment(1),
                  hasOpenPayments: true,
                }),
                managementFee: managementFee || 0,
                updatedDateTime: dateNow,
              },
            );
          }

          transaction.set(paymentRef, newPayment);
          transaction.update(
            insertedAssetId,
            {
              ...(!proForma && { currentValueEuro: new BigNumber(asset.currentValueEuro || 0).plus(amount).toNumber() }),
              sharesAvailable: new BigNumber(asset.sharesAvailable).minus(sharesAmount).toNumber(),
              updatedDateTime: dateNow,
            },
          );

          // Return the target
          return {
            investmentId: investmentRef.id,
            paymentId: paymentRef.id,
            investor: removeDocReferences(investor),
            asset: removeDocReferences(asset),
          };
        }),
      );
      if (transactionError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'createPayment' });
      }

      // Send email out of the db transaction to avoid multiple sending
      if (sendEmail) {
        const [sendEmailError] = await to(bloqifyFunctions.httpsCallable('sendSharesEmail')({
          lang: 'nl',
          investor: transactionSuccess!.investor,
          asset: transactionSuccess!.asset,
          status: proForma ? PaymentStatus.Open : PaymentStatus.Paid,
        }));
        if (sendEmailError) {
          // Log error but don't raise exception
          console.error(sendEmailError);
        }
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, payload: transactionSuccess, operation: 'createPayment' });
    },
    async updatePayment(
      { commit },
      {
        amount,
        assetId,
        investorId,
        paymentDateTime,
        investmentId: sourceInvestmentId,
        paymentId: sourcePaymentId,
        dividendsFormat,
        endDateTime: paymentEndDateTime,
        managementFee,
        type,
      }: UpdatePaymentPayload,
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'updatePayment' });
      const timeNow = firebase.firestore.FieldValue.serverTimestamp();

      const [transactionError, transactionSuccess] = await to(
        bloqifyFirestore.runTransaction(async (transaction): Promise<any> => {
          const investmentRef = bloqifyFirestore.collection('investments').doc(sourceInvestmentId);
          const paymentRef = investmentRef.collection('payments').doc(sourcePaymentId);

          const [getInvestmentError, getInvestment] = await to(transaction.get(investmentRef));
          if (getInvestmentError || !getInvestment!.exists) {
            throw Error(getInvestmentError?.message || 'Investment not found.');
          }
          const investment = getInvestment!.data() as Investment;

          const [getPaymentError, getPayment] = await to(transaction.get(paymentRef));
          if (getPaymentError || !getPayment!.exists) {
            throw Error(getPaymentError?.message || 'Payment not found.');
          }
          const payment = getPayment!.data() as Payment;

          if (moment(payment.paymentDateTime?.toMillis()).format('DD/MM/YYYY') !== moment(paymentDateTime).format('DD/MM/YYYY')) {
            if (payment.providerData.status === PaymentStatus.Paid) {
              // Check if the previous payment date affects invoices
              const [checkOldError, checkOldResult] = await to(periodCheck(investment.asset.id!, payment.paymentDateTime!.toMillis()));
              if (checkOldError) {
                throw checkOldError;
              }
              if (!checkOldResult) {
                throw Error('Previous payment date affects already generated invoices');
              }

              // Check if the new payment date affects invoices
              const [checkNewError, checkNewResult] = await to(periodCheck(investment.asset.id!, paymentDateTime!.valueOf()));
              if (checkNewError) {
                throw checkNewError;
              }
              if (!checkNewResult) {
                throw Error('New payment date affects already generated invoices');
              }

              // Update payment
              const paymentDate = firebase.firestore.Timestamp.fromMillis(paymentDateTime!.valueOf());
              transaction.update(paymentRef, {
                paymentDateTime: paymentDate,
                updatedDateTime: timeNow,
              } as Payment);
            }
          }

          transaction.update(investmentRef, {
            managementFee: managementFee || 0,
            updatedDateTime: timeNow,
          } as Investment);
        }),
      );
      if (transactionError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'updatePayment' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, payload: transactionSuccess, operation: 'updatePayment' });
    },
    async deletePayment(
      { commit },
      { investmentId, paymentId }: { investmentId: string, paymentId: string },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'deletePayment' });

      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any | Error> => {
        const countsRef = bloqifyFirestore.collection('settings').doc('counts');
        const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
        const paymentRef = investmentRef.collection('payments').doc(paymentId);
        const timeNow = firebase.firestore.FieldValue.serverTimestamp();

        const [readInvestmentError, readInvestmentSuccess] = await to(transaction.get(investmentRef));
        if (readInvestmentError || !readInvestmentSuccess!.exists) {
          throw readInvestmentError || Error('Error getting the investment.');
        }
        const [readPaymentError, readPaymentSuccess] = await to(transaction.get(paymentRef));
        if (readPaymentError || !readPaymentSuccess!.exists) {
          throw readPaymentError || Error('Error getting the payment.');
        }

        const investment = readInvestmentSuccess!.data() as Investment;
        const payment = readPaymentSuccess!.data() as Payment;
        const assetRef = investment.asset as firebase.firestore.DocumentReference;
        const [readAssetError, readAssetSuccess] = await to(transaction.get(assetRef));
        if (readAssetError || !readAssetSuccess!.exists) {
          throw readAssetError || Error('Error getting the asset.');
        }

        const asset = readAssetSuccess!.data() as Asset;

        if (payment.deleted) {
          throw Error('This payment was already deleted.');
        }

        if (payment.providerData.status === PaymentStatus.Paid) {
          const [checkError, checkResult] = await to(periodCheck(assetRef.id, payment.paymentDateTime!.toMillis()));
          if (checkError) {
            throw checkError;
          }
          if (!checkResult) {
            throw Error('Can`t change asset financials for already generated periods');
          }
        }

        const amount = payment.providerData.metadata.euroAmount;
        const sharesAmount = payment.providerData.metadata.sharesAmount;
        const status = payment.providerData.status;

        // Update payment
        transaction.update(paymentRef, {
          deleted: true,
          updatedDateTime: timeNow,
        });

        // Update asset
        transaction.update(assetRef, {
          ...(status === PaymentStatus.Paid && {
            currentValueEuro: new BigNumber(asset.currentValueEuro || 0).minus(amount).toNumber(),
          }),
          ...([PaymentStatus.Open, PaymentStatus.Requested, PaymentStatus.Paid].includes(status) && {
            sharesAvailable: firebase.firestore.FieldValue.increment(sharesAmount),
          }),
          updatedDateTime: timeNow,
        });

        // Update investment
        const paymentsRef = investmentRef.collection('payments').where('deleted', '==', false);
        const [getPaymentsError, getPaymentsSuccess] = await to(paymentsRef.get());
        if (getPaymentsError) {
          throw Error('Can`t retrieve payments collection of the investment');
        }

        transaction.update(investmentRef, {
          ...(status === PaymentStatus.Paid && {
            paidEuroTotal: new BigNumber(investment.paidEuroTotal || 0).minus(amount).toNumber(),
            boughtSharesTotal: firebase.firestore.FieldValue.increment(-sharesAmount),
            paidPayments: firebase.firestore.FieldValue.increment(-1),
          }),
          ...([PaymentStatus.Open, PaymentStatus.Requested].includes(status) && {
            openEuroTotal: new BigNumber(investment.openEuroTotal || 0).minus(amount).toNumber(),
            openSharesTotal: firebase.firestore.FieldValue.increment(-sharesAmount),
            openPayments: firebase.firestore.FieldValue.increment(-1),
            hasOpenPayments: investment.openPayments! > 1,
          }),
          // If there is only one non delete payment then is the one we are deleting now
          ...(getPaymentsSuccess?.size === 1 && {
            deleted: true,
          }),
          updatedDateTime: timeNow,
        });

        // Update counts
        transaction.update(countsRef, {
          ...(status === PaymentStatus.Paid && {
            paidPayments: firebase.firestore.FieldValue.increment(-1),
          }),
          ...([PaymentStatus.Open, PaymentStatus.Requested].includes(status) && {
            openPayments: firebase.firestore.FieldValue.increment(-1),
            hasOpenPayments: investment.openPayments! > 1,
          }),
          updatedDateTime: timeNow,
        });
      }));
      if (transactionError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'deletePayment' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'deletePayment' });
    },
    async endPayment(
      { commit },
      { investmentId, paymentId, endDate }: { investmentId: string, paymentId: string, endDate?: number },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'endPayment' });
      return commit(SET_PAYMENT, {
        status: DataContainerStatus.Error,
        payload: 'Feature disabled',
        operation: 'endPayment',
      });

      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any | Error> => {
        const countsRef = bloqifyFirestore.collection('settings').doc('counts');
        const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
        const paymentRef = investmentRef.collection('payments').doc(paymentId);
        const timeNow = firebase.firestore.Timestamp.now();
        const endingDate = (endDate && firebase.firestore.Timestamp.fromMillis(endDate)) || timeNow;

        const [readInvestmentError, readInvestmentSuccess] = await to(transaction.get(investmentRef));
        if (readInvestmentError || !readInvestmentSuccess!.exists) {
          throw readInvestmentError || Error('Error getting the investment.');
        }
        const [readPaymentError, readPaymentSuccess] = await to(transaction.get(paymentRef));
        if (readPaymentError || !readPaymentSuccess!.exists) {
          throw readPaymentError || Error('Error getting the payment.');
        }

        const investment = readInvestmentSuccess!.data() as Investment;
        const payment = readPaymentSuccess!.data() as Payment;
        const assetRef = investment.asset as firebase.firestore.DocumentReference;
        const [readAssetError, readAssetSuccess] = await to(transaction.get(assetRef));
        if (readAssetError || !readAssetSuccess!.exists) {
          throw readAssetError || Error('Error getting the asset.');
        }

        const asset = readAssetSuccess!.data() as Asset;

        if (payment.providerData.status !== PaymentStatus.Paid) {
          throw Error('You can only end paid payments');
        }
        if (payment.ended) {
          throw Error('Cannot end an ended payment');
        }
        if (payment.deleted) {
          throw Error('Cannot end a deleted payment');
        }
        if (endingDate < asset.startDateTime) {
          throw new Error('The payment cannot be ended before the start date of the selected asset.');
        }
        if (endingDate < payment.paymentDateTime!) {
          throw new Error('The payment cannot be ended before the paid date.');
        }

        const [checkError, checkResult] = await to(periodCheck(assetRef.id, endingDate.toMillis()));
        if (checkError) {
          throw checkError;
        }
        if (!checkResult) {
          throw Error('Can`t change asset financials for already generated periods');
        }

        // Only restore the share fields if the payment was already paid. Otherwise the paymentWebHook already took care of it
        const amount = payment.providerData.metadata.euroAmount;
        const sharesAmount = payment.providerData.metadata.sharesAmount;

        // Update asset
        transaction.update(assetRef, {
          currentValueEuro: new BigNumber(asset.currentValueEuro || 0).minus(amount).toNumber(),
          sharesAvailable: firebase.firestore.FieldValue.increment(sharesAmount),
          updatedDateTime: timeNow,
        });

        // Update investment
        transaction.update(investmentRef, {
          paidEuroTotal: new BigNumber(investment.paidEuroTotal || 0).minus(amount).toNumber(),
          boughtSharesTotal: firebase.firestore.FieldValue.increment(-sharesAmount),
          paidPayments: firebase.firestore.FieldValue.increment(-1),
          updatedDateTime: timeNow,
        });

        // Update payment
        transaction.update(paymentRef, {
          ended: endingDate,
          updatedDateTime: timeNow,
        });

        // Update counts
        transaction.update(countsRef, {
          paidPayments: firebase.firestore.FieldValue.increment(-1),
        });
      }));
      if (transactionError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: transactionError, operation: 'endPayment' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'endPayment' });
    },
    async cancelPayment(
      { commit },
      { investmentId, paymentId }: { investmentId: string, paymentId: string },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'cancelPayment' });

      const [cancelPaymentError] = await to(bloqifyFunctions.httpsCallable('cancelPayment')({ investmentId, paymentId }));
      if (cancelPaymentError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: cancelPaymentError, operation: 'cancelPayment' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'cancelPayment' });
    },
    async refundPayment(
      { commit },
      { investmentId, paymentId }: { investmentId: string, paymentId: string },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'refundPayment' });

      const [refundPaymentError] = await to(bloqifyFunctions.httpsCallable('refundPayment')({ investmentId, paymentId }));
      if (refundPaymentError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: refundPaymentError, operation: 'refundPayment' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'refundPayment' });
    },
    async markPaymentAsPaid(
      { commit },
      { investmentId, paymentId, paymentDate }: { investmentId: string, paymentId: string, paymentDate: Date },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'markPaymentAsPaid' });

      const [markPaymentAsPaidError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<any | Error> => {
        const countsRef = bloqifyFirestore.collection('settings').doc('counts');
        const investmentRef = bloqifyFirestore.collection('investments').doc(investmentId);
        const paymentRef = investmentRef.collection('payments').doc(paymentId);
        const timeNow = firebase.firestore.FieldValue.serverTimestamp();

        const [readInvestmentError, readInvestmentSuccess] = await to(transaction.get(investmentRef));
        if (readInvestmentError || !readInvestmentSuccess!.exists) {
          throw readInvestmentError || Error('Error getting the investment.');
        }
        const [readPaymentError, readPaymentSuccess] = await to(transaction.get(paymentRef));
        if (readPaymentError || !readPaymentSuccess!.exists) {
          throw readPaymentError || Error('Error getting the payment.');
        }

        const investment = readInvestmentSuccess!.data() as Investment;
        const payment = readPaymentSuccess!.data() as Payment;
        const assetRef = investment.asset as firebase.firestore.DocumentReference;
        const [readAssetError, readAssetSuccess] = await to(transaction.get(assetRef));
        if (readAssetError || !readAssetSuccess!.exists) {
          throw readAssetError || Error('Error getting the asset.');
        }

        const asset = readAssetSuccess!.data() as Asset;

        if (![PaymentStatus.Open, PaymentStatus.Requested].includes(payment.providerData.status)) {
          throw Error('You can only mark as paid open payments');
        }
        if (payment.ended) {
          throw Error('Can`t mark as paid an ended payment');
        }
        if (payment.deleted) {
          throw Error('Can`t mark as paid a deleted payment');
        }

        const [checkError, checkResult] = await to(periodCheck(assetRef.id, paymentDate.valueOf()));
        if (checkError) {
          throw checkError;
        }
        if (!checkResult) {
          throw Error('Can`t change asset financials for already generated periods');
        }

        // Update payment
        transaction.update(paymentRef, {
          providerData: {
            ...payment.providerData,
            status: PaymentStatus.Paid,
          },
          paymentDateTime: firebase.firestore.Timestamp.fromDate(paymentDate),
          updatedDateTime: timeNow,
        } as Payment);

        const amount = payment.providerData.metadata.euroAmount;
        const sharesAmount = payment.providerData.metadata.sharesAmount;

        // Update investment
        transaction.update(investmentRef, {
          paidEuroTotal: new BigNumber(investment.paidEuroTotal || 0).plus(amount).toNumber(),
          openEuroTotal: new BigNumber(investment.openEuroTotal || 0).minus(amount).toNumber(),
          boughtSharesTotal: firebase.firestore.FieldValue.increment(sharesAmount),
          openSharesTotal: new BigNumber(investment.openSharesTotal || 0).minus(sharesAmount).toNumber(),
          openPayments: firebase.firestore.FieldValue.increment(-1),
          paidPayments: firebase.firestore.FieldValue.increment(1),
          hasOpenPayments: investment.openPayments! > 1,
          updatedDateTime: timeNow,
        });

        // Update asset
        transaction.update(assetRef, {
          currentValueEuro: new BigNumber(asset.currentValueEuro || 0).plus(amount).toNumber(),
          updatedDateTime: timeNow,
        });

        // Update counts
        transaction.update(countsRef, {
          openPayments: firebase.firestore.FieldValue.increment(-1),
          paidPayments: firebase.firestore.FieldValue.increment(1),
        });
      }));
      if (markPaymentAsPaidError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: markPaymentAsPaidError, operation: 'markPaymentAsPaid' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'markPaymentAsPaid' });
    },
    async markPaymentAsRequested(
      { commit },
      { investmentId, paymentId }:
      { investmentId: string, paymentId: string },
    ): Promise<void> {
      commit(SET_PAYMENT, { status: DataContainerStatus.Processing, operation: 'markPaymentAsRequested' });

      const [requestPaymentError] = await to(bloqifyFunctions.httpsCallable('requestPayment')({ mode: 'payment', investmentId, paymentId }));
      if (requestPaymentError) {
        return commit(SET_PAYMENT, { status: DataContainerStatus.Error, payload: requestPaymentError, operation: 'markPaymentAsRequested' });
      }

      return commit(SET_PAYMENT, { status: DataContainerStatus.Success, operation: 'markPaymentAsRequested' });
    },
  },
  getters: {},
};
