import { Module } from 'vuex';
import { State } from '@/models/State';
import { bloqifyFirestore, firebase } from '@/boot/firebase';
import { Asset, InterestRateTypes } from '@/models/assets/Asset';
import { AssetInterestRateType, InterestRateTypeStatus } from '@/models/assets/InterestRateTypes';
import { DataContainerStatus } from '@/models/Common';
import to from 'await-to-js';
import { periodCheck } from '../utils/checks';
import { Vertebra, generateState, mutateState } from '../utils/skeleton';
import { CustomBatch } from '../utils/customBatch';

export interface DeleteInterestRateTypeParam {
  assetId: string,
  interestRateTypeId: string,
}

export interface AddInterestRateTypeParam {
  assetId: string;
  type: InterestRateTypes;
  date: Date;
  amount?: number;
}

const SET_INTERESTRATETYPE = 'SET_INTERESTRATETYPE';

export default <Module<Vertebra, State>> {
  state: generateState(),
  mutations: {
    [SET_INTERESTRATETYPE](state, { status, payload, operation }: { status: DataContainerStatus, payload?: any, operation: string }): void {
      mutateState(state, status, operation, payload);
    },
  },
  actions: {
    async addInterestRateType(
      { commit },
      { assetId, type, date, amount }: AddInterestRateTypeParam,
    ): Promise<void> {
      commit(SET_INTERESTRATETYPE, { status: DataContainerStatus.Processing, operation: 'addInterestRateType' });
      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const transactionBatch = new CustomBatch(transaction);
        const now = new Date();
        const futureDate = now < date;

        const [getAssetError, getAsset] = await to(transaction.get(bloqifyFirestore.collection('assets').doc(assetId)));
        if (getAssetError || !getAsset || !getAsset.exists) {
          throw Error(getAssetError?.message || 'Asset not found');
        }
        const asset = getAsset.data() as Asset;

        const [checkError, checkResult] = await to(periodCheck(assetId, date.valueOf()));
        if (checkError) {
          throw checkError;
        }
        if (!checkResult) {
          throw Error('Can`t change asset financials for already generated periods');
        }

        const [checkExistError, checkExist] = await to(getAsset.ref.collection('interestRateTypes')
          .where('deleted', '==', false)
          .where('appliedDateTime', '==', date)
          .get());
        if (checkExistError) {
          throw Error(checkExistError.message);
        }
        if (!checkExist!.empty) {
          throw Error('There is already an interest rate type change planned for that period');
        }

        const interestRateRef = getAsset.ref.collection('interestRateTypes').doc();
        const data = {
          asset: getAsset.ref,
          deleted: false,
          type,
          ...(amount && { amount }),
          status: futureDate ? InterestRateTypeStatus.Pending : InterestRateTypeStatus.Applied,
          appliedDateTime: firebase.firestore.Timestamp.fromDate(date),
          createdDateTime: firebase.firestore.Timestamp.fromDate(now),
          updatedDateTime: firebase.firestore.Timestamp.fromDate(now),
        } as AssetInterestRateType;

        transactionBatch.addOperation({
          ref: interestRateRef,
          data,
        });

        const [getAllInterestRateTypesError, getAllInterestRateTypes] = await to((getAsset.ref.collection('interestRateTypes')
          .where('deleted', '==', false)
          .where('status', '==', InterestRateTypeStatus.Applied)
          .orderBy('appliedDateTime', 'desc')
          .get()));

        if (getAllInterestRateTypesError) {
          throw Error(getAllInterestRateTypesError.message);
        }

        const recentDate = date.valueOf() >= (getAllInterestRateTypes?.docs[0]?.get('appliedDateTime')?.toDate().valueOf() || -Infinity);

        if (!futureDate && recentDate) {
          transactionBatch.addOperation({
            ref: getAsset.ref,
            data: {
              interestRateType: type,
              fixedAmortisationRate: amount || 0,
              updatedDateTime: firebase.firestore.Timestamp.fromDate(now),
            } as Asset,
          });
        }

        transactionBatch.commit();
      }));
      if (transactionError) {
        return commit(SET_INTERESTRATETYPE, { status: DataContainerStatus.Error, payload: transactionError, operation: 'addInterestRateType' });
      }
      return commit(SET_INTERESTRATETYPE, { status: DataContainerStatus.Success, operation: 'addInterestRateType' });
    },
    async deleteInterestRateType(
      { commit },
      { assetId, interestRateTypeId }: DeleteInterestRateTypeParam,
    ): Promise<void> {
      commit(SET_INTERESTRATETYPE, { status: DataContainerStatus.Processing, operation: 'deleteInterestRateType' });
      const [transactionError] = await to(bloqifyFirestore.runTransaction(async (transaction): Promise<void> => {
        const transactionBatch = new CustomBatch(transaction);
        const now = new Date();
        if (interestRateTypeId === 'initial') {
          throw Error('Can\'t delete initial interest rate type of the project');
        }

        const [getAssetError, getAsset] = await to(transaction.get(bloqifyFirestore.collection('assets').doc(assetId)));
        if (getAssetError || !getAsset || !getAsset.exists) {
          throw Error(getAssetError?.message || 'Asset not found');
        }
        const asset = getAsset.data() as Asset;

        const [getInterestRateTypeError, getInterestRateType] = await to(transaction.get(getAsset.ref.collection('interestRateTypes').doc(interestRateTypeId)));
        if (getInterestRateTypeError || !getInterestRateType || !getInterestRateType.exists) {
          throw Error(getInterestRateTypeError?.message || 'Interest rate type not found');
        }
        const interestRate = getInterestRateType.data() as AssetInterestRateType;

        if (interestRate.deleted) {
          throw Error('Interest rate type already erased');
        }

        const [checkError, checkResult] = await to(periodCheck(assetId, interestRate.appliedDateTime.toMillis()));
        if (checkError) {
          throw checkError;
        }
        if (!checkResult) {
          throw Error('Can`t change asset financials for already generated periods');
        }

        transactionBatch.addOperation({
          ref: getInterestRateType.ref,
          data: {
            deleted: true,
            updatedDateTime: firebase.firestore.Timestamp.fromDate(now),
          } as AssetInterestRateType,
        });

        const [getAllInterestRateTypesError, getAllInterestRateTypes] = await to(getAsset.ref.collection('interestRateTypes')
          .where('deleted', '==', false)
          .where('status', '==', InterestRateTypeStatus.Applied)
          .orderBy('appliedDateTime', 'desc')
          .get());

        if (getAllInterestRateTypesError) {
          throw Error(getAllInterestRateTypesError.message);
        }

        const lastEndDate = getAllInterestRateTypes!.docs[0].id === getInterestRateType.id;

        if (lastEndDate) {
          if (getAllInterestRateTypes!.size <= 1) {
            throw Error('Not previous end date found');
          }
          const previousInterestRateType = getAllInterestRateTypes!.docs[1].data() as AssetInterestRateType;

          transactionBatch.addOperation({
            ref: getAsset.ref,
            data: {
              interestRateType: previousInterestRateType.type,
              fixedAmortisationRate: previousInterestRateType.amount || 0,
              updatedDateTime: firebase.firestore.Timestamp.fromDate(now),
            } as Asset,
          });
        }

        transactionBatch.commit();
      }));
      if (transactionError) {
        return commit(SET_INTERESTRATETYPE, { status: DataContainerStatus.Error, payload: transactionError, operation: 'deleteInterestRateType' });
      }
      return commit(SET_INTERESTRATETYPE, { status: DataContainerStatus.Success, operation: 'deleteInterestRateType' });
    },
  },
};
