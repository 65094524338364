import firebase from 'firebase/app';
import { OppStatus, MandateStatus, AssetPaymentMethod } from '../opp/Opp';
import { Investor } from '../users/User';
import { SetupFee, SetupWhOrEe } from './Fees';

export enum InterestRateTypes {
  Bullet = 'bullet',
  FixedAmortisation = 'fixedAmortisation',
  Linear = 'linear',
  Annuity = 'annuity',
  Custom = 'custom'
}

export enum InvoiceFrequency {
  Monthly = 'monthly',
  Quaterly = 'quaterly',
  'Bi-Yearly' = 'bi-yearly',
  Yearly = 'yearly',
}

export enum DateRangeFormat {
  FixedDates = 'fixedDates',
  RollingDates = 'rollingDates',
}

export enum FundingTypes {
  Online = 'online',
  Offline = 'offline',
  Reservation = 'reservation'
}

export interface Asset {
  id?: string;
  customId: number;
  externalId: string;
  emailAddressDummy: string;
  projectURL?: string;
  walletId?: string; // OPP merchant uid
  mainProfile?: string; // OPP Deposit profile uid
  depositProfile?: string; // OPP Deposit profile uid
  oppData?: OppStatus; // Opp merchant status
  balance?: number; // OPP merchant balance
  depositBalance?: number // OPP Deposit profile balance
  mandateId?: string; // OPP mandate uid
  mandateStatus?: MandateStatus; // OPP mandate status
  mandateVerification?: string // OPP mandate verification url
  paymentMethod?: AssetPaymentMethod;
  psp: boolean;
  brochure?: string[];
  city: string;
  country: string;
  createdDateTime: firebase.firestore.Timestamp;
  deleted: boolean;
  /**
   * Firestore limitation of nested arrays forces to create intermediate object
   * when `dividendsFormat.contents[0][0] === 'fixed'`, it's fixed net dividends, so contents `length === 1`.
   */
  dividendsFormat: { contents: [string, number] }[];
  euroMin: number;
  euroMax: number;
  investmentStepSize: number;
  fixedDividends?: boolean;
  floorPlanImages?: string[];
  houseNumber: string;
  images?: string[];
  investmentCase: string;
  name: string;
  postalCode: string;
  premium: boolean;
  propertyDetails: string;
  prospectus?: string[];
  published: boolean;
  activated: boolean;
  finished: boolean;
  hidden: boolean;
  returnsAfterEnd: number;
  fundingType: string;
  sharePrice: number;
  sharesAvailable: number;
  startDateTime: firebase.firestore.Timestamp;
  endDateTime: firebase.firestore.Timestamp;
  invoiceFrequency: InvoiceFrequency;
  dateRangeFormat: DateRangeFormat;
  numberOfPeriods: number;
  lastRepaymentDate?: firebase.firestore.Timestamp;
  street: string;
  shortDescription?: string;
  totalValueEuro: number;
  totalValueShares: number;
  currentValueEuro?: number;
  updatedDateTime?: firebase.firestore.Timestamp;
  reservation?: boolean;
  loanTaker?: (firebase.firestore.DocumentReference | Investor)[];
  notary?: firebase.firestore.DocumentReference | Investor;
  sendInvoices: boolean;
  createInvoices: boolean;
  overrideInvoiceSchedules: boolean;
  sendInvoiceDate: number;
  createInvoiceDate: number;
  entryFee?: number;
  exitFee?: number;
  emissionCost?: number;
  applicationFee?: number;
  administrationFee: number;
  managementFee: number;
  invoiceFees?: SetupFee[];
  invoiceWithholdings?: SetupWhOrEe[];
  invoiceExternalEarnings?: SetupWhOrEe[];
  expectedRepaymentDate?: firebase.firestore.Timestamp;
  interestRateType: InterestRateTypes;
  fixedAmortisationRate?: number;
  vimeoId?: string;
}

// Asset Converted created in order to just load from firestore the required fields
export class AssetConverter {
  constructor(
    readonly createdDateTime: firebase.firestore.Timestamp,
    readonly name: string,
    readonly endDateTime: firebase.firestore.Timestamp,
    readonly startDateTime: firebase.firestore.Timestamp,
    readonly dividendsFormat: Array<any>,
    readonly fixedDividends: boolean,
    readonly returnsAfterEnd: number,
    readonly deleted: boolean,
    readonly published: boolean,
    readonly activated: boolean,
    readonly hidden: boolean,
    readonly loanTaker: firebase.firestore.DocumentReference[],
  ) {
    this.createdDateTime = createdDateTime;
    this.name = name;
    this.endDateTime = endDateTime;
    this.startDateTime = startDateTime;
    this.dividendsFormat = dividendsFormat;
    this.fixedDividends = fixedDividends;
    this.returnsAfterEnd = returnsAfterEnd;
    this.deleted = deleted;
    this.published = published;
    this.activated = activated;
    this.hidden = hidden;
    this.loanTaker = loanTaker;
  }
}

export const assetConverter = {
  toFirestore(asset): firebase.firestore.DocumentData {
    return {
      createdDateTime: asset.createdDateTime,
      name: asset.name,
      endDateTime: asset.endDateTime,
      startDateTime: asset.tartDateTime,
      dividendsFormat: asset.dividendsFormat,
      fixedDividends: asset.fixedDividends,
      returnsAfterEnd: asset.returnsAfterEnd,
      deleted: asset.deleted,
      published: asset.published,
      activated: asset.activated,
      hidden: asset.hidden,
      loanTaker: asset.loanTaker,
    };
  },
  fromFirestore(
    snapshot: firebase.firestore.QueryDocumentSnapshot,
    options: firebase.firestore.SnapshotOptions,
  ): AssetConverter {
    const data = snapshot.data(options)!;
    return new AssetConverter(
      data.createdDateTime,
      data.name,
      data.endDateTime,
      data.tartDateTime,
      data.dividendsFormat,
      data.fixedDividends,
      data.returnsAfterEnd,
      data.deleted,
      data.published,
      data.activated,
      data.hidden,
      data.loanTaker,
    );
  },
};

export enum AssetStatus {
  All = 'all',
  Draft = 'draft',
  Funding = 'funding',
  Activated = 'activated',
  Finished = 'finished'
}
